@mixin pull($direction: '') {
  .pull#{$direction}-xxl {
    margin#{$direction}: -56px;
  }
  .pull#{$direction}-xl {
    margin#{$direction}: -48px;
  }
  .pull#{$direction}-lg {
    margin#{$direction}: -32px;
  }
  .pull#{$direction}-md {
    margin#{$direction}: -24px;
  }
  .pull#{$direction} {
    margin#{$direction}: -16px;
  }
  .pull#{$direction}-sm {
    margin#{$direction}: -8px;
  }
  .pull#{$direction}-xs {
    margin#{$direction}: -4px;
  }
  .pull#{$direction}-none {
    margin#{$direction}: 0;
  }
}

@mixin td-pulling-utilities() {
  body {
    @include pull();
    @include pull(-bottom);
    @include pull(-top);
    @include pull(-left);
    @include pull(-right);
  }
}
